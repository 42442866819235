@import 'maps';

// Colours

$green: map-get($map: $colors, $key: green);
$green-dark: map-get($map: $colors, $key: green-dark);
$green-faint-dark: map-get($map: $colors, $key: green-faint-dark);
$sand-light: map-get($map: $colors, $key: sand-light);

$purple: map-get($map: $colors, $key: purple);
$purple-dark: map-get($map: $colors, $key: purple-dark);
$purple-light: map-get($map: $colors, $key: purple-light);
$purple-extra-light: map-get($map: $colors, $key: purple-extra-light);

$blue: map-get($map: $colors, $key: blue);
$blue-dark: map-get($map: $colors, $key: blue-dark);
$blue-light: map-get($map: $colors, $key: blue-light);
$blue-extra-light: map-get($map: $colors, $key: blue-extra-light);

$orange: map-get($map: $colors, $key: orange);
$orange-dark: map-get($map: $colors, $key: orange-dark);
$orange-light: map-get($map: $colors, $key: orange-light);
$orange-extra-light: map-get($map: $colors, $key: orange-extra-light);

$pink: map-get($map: $colors, $key: pink);
$pink-dark: map-get($map: $colors, $key: pink-dark);
$pink-light: map-get($map: $colors, $key: pink-light);
$pink-extra-light: map-get($map: $colors, $key: pink-extra-light);

$yellow: map-get($map: $colors, $key: yellow);
$yellow-dark: map-get($map: $colors, $key: yellow-dark);
$yellow-light: map-get($map: $colors, $key: yellow-light);
$yellow-extra-light: map-get($map: $colors, $key: yellow-extra-light);

$black: map-get($map: $colors, $key: black);
$grey-400: map-get($map: $colors, $key: grey-400);
$grey-300: map-get($map: $colors, $key: grey-300);
$grey-200: map-get($map: $colors, $key: grey-200);
$grey-100: map-get($map: $colors, $key: grey-100);
$white: map-get($map: $colors, $key: white);

$red-error: map-get($map: $colors, $key: red-error);
$blue-info: map-get($map: $colors, $key: blue-info);

//components specific
$btn-primary-secondary-hover: map-get($map: $colors, $key: btn-primary-secondary-hover);
$btn-tertiary-hover: map-get($map: $colors, $key: btn-tertiary-hover);
$btn-alternative-hover: map-get($map: $colors, $key: btn-alternative-hover);
