@import 'src/scss/variables';
@import 'src/scss/utilities/breakpoints';

.page {
    section {
        padding-top: 2rem;
        padding-bottom: 2rem;
        @include md-up {
            padding-top: 4rem;
            padding-bottom: 4rem;
        }
        h1,
        h2 {
            color: $green-dark;
        }
        > h2 {
            text-align: center;
            padding-left: 1rem;
            padding-right: 1rem;
        }
        background-color: $white;
    }
}

.hero {
    .hero-tiles {
        width: 105%;
        animation: nudge 2s ease-in-out 1s forwards;
        pointer-events: none;
        @include md-up {
            width: 100%;
            animation: none;
            pointer-events: auto;
        }
        img {
            height: 100%;
            width: 100%;
            object-fit: cover;
            object-position: top;
        }
    }
    .hero-tiles--carousel {
        @apply mb-8 flex snap-x snap-mandatory space-x-4 overflow-x-auto overflow-y-hidden pb-4 pl-4 pr-4;
        @apply md:mb-0 md:grid md:w-full md:grid-cols-3 md:grid-rows-2 md:gap-x-4 md:gap-y-4 md:space-x-0 md:overflow-visible md:px-0;
        @apply xl:flex xl:overflow-x-auto xl:overflow-y-hidden  xl:px-4;
        @include ipad-landscape-only {
            @apply lg:space-x-4;
        }
    }
}
